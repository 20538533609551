
import { useAPI } from '../../composables/api'
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	setup() {
		const { t, locale } = useI18n({ useScope: 'global' })
		const country = ref('RU')
		const loading = ref(false)
		const api = useAPI()

		const getCountry = async () => {
			loading.value = true

			try {
				const res = await api({
					path: '/country',
					string: true
				})

				country.value = res
			} catch (error) {
				// silent
			}

			loading.value = false
		}

		onMounted(async () => {
			await getCountry()
		})

		return {
			t,
			locale,
			country,
			loading
		}
	}
})

<template>
	<div class="text">
		<div class="text-header">
			<img
				class="header-logo"
				:src="require('../../assets/icons/logo-half.svg')"
			/>
			<div class="header-title">{{ t('Contacts.title') }}</div>
		</div>
		<div class="text-body" v-if="loading">
			<p>Loading...</p>
		</div>
		<template v-else>
			<div class="text-body" v-if="country === 'RU'">
				<p>
					Кыргызская республика, г.Бишкек, Первомайский р-он, ул. Раззакова, 19. Бц "Россия", офис 503
				</p>
				<p>
					ООО "Моментальные платежи"
				</p>
				<br />
				<p>Partnership: support@inshop.gg</p>
			</div>
			<div class="text-body" v-else>
				<p>
					CRYSTAL FUTURE OÜ
				</p>
				<p>
					Harju maakond, Tallin, Kesklinna linnaosa, Masina
					tn 22, 10113.
				</p>
				<br />
				<p>Partnership: support@inshop.gg</p>
			</div>
		</template>
	</div>
</template>
<script lang="ts">
import { useAPI } from '../../composables/api'
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	setup() {
		const { t, locale } = useI18n({ useScope: 'global' })
		const country = ref('RU')
		const loading = ref(false)
		const api = useAPI()

		const getCountry = async () => {
			loading.value = true

			try {
				const res = await api({
					path: '/country',
					string: true
				})

				country.value = res
			} catch (error) {
				// silent
			}

			loading.value = false
		}

		onMounted(async () => {
			await getCountry()
		})

		return {
			t,
			locale,
			country,
			loading
		}
	}
})
</script>
<style scoped>
@import url('../../assets/styles/text.css');
</style>
